<style lang="scss" scoped>

    .quotation-order {

    }

    .quotation-order-form {
        background: #fff;

        .color-blue {
            background: #1890FF;
            border: 1px solid #1890FF;
            color: #FFFFFF;
        }

        .el-button + .el-button {
            margin-left: 10px;
            font-size: 14px;
        }

        .el-form-item {
            margin-bottom: 15px;
        }
    }

    .quotation-order /deep/ {
        .el-form-item__label {
            padding: 0;
        }

        .quotation-order-form {
            .el-form-item {
                display: flex;

                &:last-child {
                    margin-bottom: 0;

                    .el-form-item {
                        margin-bottom: 0;
                    }
                }
            }

            .el-form-item__content {
                display: flex;
                background: #fff;
            }

            .way-address-place {

                .el-form-item__content {
                    background: transparent;
                }

                .box-search-address {
                    background: #fff;
                }
            }
        }

        .start-play-city {
            .select-city-pub.city-pub_border {
                .el-select .el-input.is-focus .el-input__inner {
                    border: 1px solid #dcdfe6;
                }

                .el-input__inner {
                    border: 1px solid #dcdfe6;
                    padding-right: 15px;
                }

                .el-input__suffix {
                    display: block;
                    opacity: 1;
                }
            }
        }
    }

    .edit-demand-box {
        display: flex;
        padding: 15px 12px;
    }

    .edit-demand-list {
        width: 475px;
        position: relative;
    }

    .demand-list-l {
        &:before {
            content: "";
            display: inline-block;
            position: absolute;
            width: 1px;
            right: 0;
            top: 0;
            bottom: 0;
            background: #DCDFE6;
        }
    }

    .edit-demand-list-r {
        .el-form-item {
            display: block;
        }
    }

    .file-down-btn {
        border: 1px solid #1989FA;
        width: 80px;
        font-size: 14px;
        color: #1989FA;
    }

    .trip-info-com {
        margin-top: 20px;
        padding: 20px;
        background: #F0F8FF;

        .trip-title {
            font-size: 14px;
            margin-bottom: 15px;
        }

        .trip-info-head {
            margin-bottom: 15px;
            font-size: 14px;

            .el-button {
                padding: 9px 20px;
                background: #E7F3FF;
                border: 1px solid #1989FA;
                color: #1890FF;
                margin-left: 5px;
            }
        }

        .trip-info-list {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .way-address-place {
        .el-form-item {
            &:last-child {
                margin-bottom: 10px;
            }
        }

        .delete-way-address {
            margin-left: 10px;
            height: 30px;
            width: 50px;
            padding: 0;
            margin-top: 1px;
        }
    }

    .submit-btn {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 30px;

        .el-button {
            width: 240px;
            border: 0;
            height: 50px;
        }
    }

    /*.edit-demand-com /deep/ {*/
    /*    .demand-list-flex {*/
    /*        display: flex;*/

    /*        &.el-form-item--small {*/
    /*            .el-form-item__content {*/
    /*                display: flex;*/
    /*            }*/
    /*        }*/
    /*    }*/
    /*}*/

    .sheet-edit-distribution {
        background: #fff;
        border-bottom: 10px solid #f8f8f8;
        padding: 0 12px;
        padding-top: 5px;
    }

    .city-site-list {
        padding-left: 96px;
        li {
            line-height: 26px;
            background: #ECF6FF;
            border-radius: 3.84px;
            display: inline-block;
            float: left;
            margin-right: 10px;
            font-size: 12px;
            color: #319BF7;
            padding: 0 8px;
            margin-bottom: 10px;
            cursor: pointer;
            &.site-list-selected {
                background: #319BF7;
                color: #FFFFFF;
            }

        }
    }

    .quotation-order {
        .quotation-order-form {
            .city-station-item.el-form-item {
                margin-bottom: 0;
            }
        }
    }
</style>
<style lang="scss">
</style>
<template>
    <div class="quotation-order">

        <el-form ref="form" :model="form" :inline="true" :rules="formRules" :size="formSize" label-width="96px"
                 class="quotation-order-form">
            <div class="sheet-edit-distribution" v-if="platformType == 2">
                <div style="display: flex">
                    <el-form-item label="运营商：" prop="productOperatorId">
                        <el-select v-model="form.productOperatorId"
                                   clearable
                                   filterable
                                   placeholder="请选择运营商"
                                   style="width: 300px"
                                   @change="handOperatorChange">
                            <el-option
                                    v-for="item in operatorOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="订单金额：" prop="orderAmount">
                        <el-input v-model="form.orderAmount" placeholder="请输入金额"
                                  style="width: 115px"
                                  @change="orderAmountChange(form.orderAmount)"></el-input>
                        <span class="ml10">元</span>
                    </el-form-item>
                    <el-form-item style="margin-left: 40px">
                        <el-button type="primary" @click="updateCustomizeOrder()" style="width: 80px;height: 32px">报价
                        </el-button>
                    </el-form-item>
                </div>
                <div style="display: flex">
                    <el-form-item label="供应商：">
                        <el-select v-model="form.resourceSupplierId"
                                   clearable
                                   filterable
                                   style="width: 300px"
                                   placeholder="请选择供应商（为空则不填）"
                                   @change="handSupplierChange">
                            <el-option
                                    v-for="item in supplierOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="结算金额：">
                        <el-input v-model="form.settleAmount"
                                  placeholder="请输入金额"
                                  style="width: 115px"
                                  @change="settleAmountChange(form.settleAmount)"></el-input>
                        <span class="ml10">元</span>
                    </el-form-item>
                </div>
            </div>
            <div class="edit-demand-box">
                <div class="edit-demand-list demand-list-l">
                    <el-form-item label="类型：">
                        <el-button :class="{'color-blue': form.productForm == item.value}"
                                   v-for="(item, index) in productOptions"
                                   :key="index" @click="handleTypeBtn(item.value)">{{item.label}}
                        </el-button>
                    </el-form-item>
                    <el-form-item label="用车城市：" class="start-play-city"
                                  v-if="form.productForm != 12">
                        <select-city :city="form.productStartCityName"
                                     :default-city="$store.state.location.cityName"
                                     @update:getCity="selectDestCityName"
                                     @onUpdate:isFocus="hideCitySel">
                        </select-city>
                    </el-form-item>
                    <div style="display: flex">
                        <el-form-item label="用车时间：" required>
                            <date-picker-with-title
                                    style="width: 126px"
                                    isTitle
                                    :heightBox="heightBox"
                                    title="用车日期"
                                    placeholder="选择用车日期"
                                    :defaultDate="form.reserveTime"
                                    :pickerOptions="newDateDis"
                                    @onUpdate="handVehicleDate"
                                    prop="reserveTime"
                            ></date-picker-with-title>
                            <time-picker-with-title
                                    style="width: 90px;margin-left: 10px;margin-bottom: 15px"
                                    isTitle
                                    :heightBox="heightBox"
                                    title="用车时间"
                                    placeholder="选择用车时间"
                                    :defaultHour="deTimeHour"
                                    :defaultMinute="deTimeMinute"
                                    :timeMinuteStep="newTimeSel.timeMinuteStep"
                                    @onUpdate="handVehicleTime"
                                    v-if="form.productForm == 12"
                                    prop="carTime"
                            ></time-picker-with-title>
                        </el-form-item>
                        <el-form-item label="用车天数：" prop="tripNum"
                                      v-if="form.productForm != 12">
                            <el-select v-model="form.tripDay" placeholder="请选择" style="width: 70px"
                                       @change="dayChange(form.tripDay)">
                                <el-option
                                        v-for="(item, index) in tripDaysOp" :key="index"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>

                            <span class="ml10">天</span>
                        </el-form-item>
                    </div>


                    <el-form-item label="出发地址：" prop="departure"
                                  v-if="form.productForm == 12">
                        <search-address
                                isSlot
                                :address="form.departure"
                                input-width="340px"
                                placeholder="请输入出发地点"
                                @update:searchAddress="handStartPlace"
                        ></search-address>
                    </el-form-item>

                    <el-form-item class="city-station-item" v-if="form.productForm == 12"
                                  :style="{'margin-bottom':(!stationList ? 0:'')}">
                        <ul class="city-site-list clear">
                            <li v-for="(s,index) in stationList"
                                :class="{'site-list-selected': citySiteOnId == s.id && form.departure.address}"
                                :key="index"
                                @click="handleCitySite(s, 1)">{{s.stationName}}
                            </li>
                        </ul>
                    </el-form-item>
                    <el-form-item label="送达地址：" prop="destination"
                                  v-if="form.productForm == 12">
                        <search-address
                                isSlot
                                :address="form.destination"
                                input-width="340px"
                                placeholder="请输入送达地点"
                                @update:searchAddress="handEndPlace"
                        ></search-address>

                    </el-form-item>
                    <el-form-item class="city-station-item" v-if="form.productForm == 12"
                                  :style="{'margin-bottom':(!stationList1 ? 0:'')}">
                        <ul class="city-site-list clear">
                            <li v-for="(s,index) in stationList1"
                                :class="{'site-list-selected': citySiteOffId == s.id && form.destination.address}"
                                :key="index"
                                @click="handleCitySite(s, 2)">{{s.stationName}}
                            </li>
                        </ul>
                    </el-form-item>

                    <el-form-item label="车型需求："
                                  v-if="form.productForm != 13">
                        <seat-model v-model="form.vehicleType" priceWay="1" placeholder="请选择"
                                    @selectSeat="handSaddleTypeChange"></seat-model>
                    </el-form-item>
                    <el-form-item label="出行人数：" prop="playPersonNumber">
                        <el-input-number v-model="form.playPersonNumber" @change="handleChange" :min="1"
                                         :max="maxPeopleNum"
                                         label="出行人数"></el-input-number>
                        <span class="ml10">人</span>
                    </el-form-item>
                    <el-form-item label="出行人：" required>
                        <el-form-item prop="tripUserName" style="width: 150px">
                            <el-input v-model="form.tripUserName" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item prop="tripUserMobile" style="width: 180px;">
                            <el-input v-model="form.tripUserMobile" placeholder="手机号" maxlength="11"></el-input>
                        </el-form-item>
                    </el-form-item>
                </div>
                <div class="edit-demand-list edit-demand-list-r">
                    <el-form-item label="人均价格：">
                        <el-input v-model="form.budgetPer" placeholder="请输入金额"
                                  style="width: 115px"
                                  @change="budgetPerChange(form.budgetPer)"></el-input>
                        <span class="ml10">元</span>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input
                                style="width: 330px;font-size: 14px"
                                type="textarea"
                                :rows="6"
                                placeholder="若有其他备注信息，请在此填写"
                                v-model="form.orderRemake"
                                resize="none">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="需求文档：" v-if="platformType == 1">
                        <div>
                            <file-upload @update:fileUploadCompleted="fileUpload" :max-size="5"
                                         accept="*"></file-upload>
                            <p style="line-height: 20px;margin-top: 8px">直接上传需求文档，报价更方便<br/>
                                支持doc/docx/xls/xlsx/ppt/pptx/pdf/png/jpg等<br/>
                                常用文件格式</p>
                        </div>
                    </el-form-item>
                    <el-form-item label="需求文档：" v-if="platformType == 2 && form.customizationFileUrl">
                        <div>
                            <el-button class="file-down-btn" @click="downUrl(form.customizationFileUrl)">下载</el-button>
                            <!--                            <p>XXXXXXX</p>-->
                        </div>
                    </el-form-item>
                </div>
            </div>


            <div class="trip-info-com"
                 v-if="form.productForm != 12">
                <div class="trip-title">行程信息：(选填，完整填写有助于客服准确报价)</div>
                <div class="trip-info-list" v-for="(item, index) in form.itineraryInfo" :key="index">
                    <div class="trip-info-head">
                        <span>第{{item.travelDay}}天（{{item.date | dateCus('yyyy-MM-dd')}}）</span>
                        <el-button @click="addWayAddress(index)">添加途径地址</el-button>
                    </div>
                    <div>
                        <el-form-item label="用车时间：" label-width="96px">
                            <time-picker-with-title
                                    style="width: 90px;"
                                    isTitle
                                    :index-ident="{index}"
                                    :heightBox="heightBox"
                                    title="用车时间"
                                    placeholder="选择用车时间"
                                    :defaultHour="item.handTripTimeHour"
                                    :defaultMinute="item.handTripTimeMinute"
                                    :timeMinuteStep="newTimeSel.timeMinuteStep"
                                    @onUpdate="handTripTime"
                            ></time-picker-with-title>
                        </el-form-item>
                        <el-form-item label="出发地址：" :prop="'itineraryInfo[' + index + '].tripStartPlace.value'"
                                      :rules="{required: true, message: '请选择上车城市', trigger: 'change'}">
                            <search-address
                                    :index-ident="{index}"
                                    :address="item.tripStartPlace"
                                    input-width="340px"
                                    placeholder="请输入出发地点"
                                    @update:searchAddress="handTripStartPlace"
                            ></search-address>
                        </el-form-item>
                        <div v-for="(list, idxAddress) in item.wayAddressList" :key="list.id"
                             style="margin-bottom: 15px">
                            <el-form-item label="途径地址：" class="way-address-place"
                                          :prop="'itineraryInfo[' + index + '].wayAddressList[' + idxAddress +'].tripWayPlace.value'"
                                          :rules="{required: true, message: '请选择途径地址', trigger: 'change'}"
                                          label-width="96px">
                                <search-address
                                        :index-ident="{index,idxAddress}"
                                        :address="list.tripWayPlace"
                                        input-width="340px"
                                        placeholder="请输入途径地址"
                                        @update:searchAddress="handWayAddressPlace"
                                ></search-address>
                                <el-button @click="deleteWayAddress(index, idxAddress)" class="delete-way-address"
                                           :style="{height:(heightBox - 2) + 'px'}"
                                           type="danger" plain>删除
                                </el-button>
                            </el-form-item>
                        </div>


                        <el-form-item label="送达地址：" :prop="'itineraryInfo[' + index + '].tripEndPlace.value'"
                                      :rules="{required: true, message: '请选择送达地址', trigger: 'change'}">
                            <search-address
                                    input-width="340px"
                                    :index-ident="{index}"
                                    :address="item.tripEndPlace"
                                    placeholder="请输入送达地点"
                                    @update:searchAddress="handTripEndPlace"
                            ></search-address>
                        </el-form-item>
                    </div>

                </div>
            </div>
            <div v-if="platformType == 1" class="submit-btn">
                <el-button @click="submitDemand()" type="primary">提交需求</el-button>
            </div>
        </el-form>

    </div>

</template>

<script type="text/ecmascript-6">

    import datePickerWithTitle from '@/components/datePickerWithTitle/index.vue'
    import timePickerWithTitle from '@/components/timePickerWithTitle/index.vue'
    // import searchAddress from '@/components/searchAddressWithTitle/index.vue'
    import searchAddress from '@/components/searchAddress/index.vue'
    import selectCity from '@/components/selectCity/'
    import fileUpload from '@/components/fileUpload/index';
    import {isCellphone, keepTwoDecimal, download} from '@/common/js/Utils';
    import SeatModel from "@/components/seatModel/index";
    import {getSeatModel} from "@/data/index";
    import {getCityData} from '@/urls/index';

    export default {
        //定义模版数据
        data() {
            const curDate1 = new Date().getTime();
            const ninetyDays = 90 * 24 * 3600 * 1000;
            const oneDayTime = 86400000;
            let threeMonthsDis = curDate1 + ninetyDays;

            let tomorrowData = this.filterDate(new Date(+new Date() + oneDayTime), 'yyyy-MM-dd');  // 明天
            return {
                formSize: '',
                heightBox: '',
                newDateDis: {
                    disabledDate(time) {
                        return time.getTime() > threeMonthsDis || time.getTime() < new Date((+Date.now() - oneDayTime));
                    },
                },
                tomorrowData: this.filterDate(new Date(+new Date() + oneDayTime), 'yyyy-MM-dd'), // 明天
                deTimeHour: "09",
                deTimeMinute: "00",
                newTimeSel: {
                    timeMinuteStep: ['00', '10', '20', '30', '40', '50'],
                },

                value: '',
                productOptions: [{
                    value: '12',
                    label: '实时用车'
                }, {
                    value: '2',
                    label: '旅游包车'
                }, {
                    value: '13',
                    label: '旅游线路'
                }],  // 产品类型
                // vehicleTypeSaddle: seatModelList,
                showHideCity: false,
                maxPeopleNum: 100,

                demandStateOptions: [{
                    value: '12',
                    label: '客服联系',
                    number: '2'
                }, {
                    value: '11',
                    label: '确认报价'
                }, {
                    value: '16',
                    label: '已成单'
                }, {
                    value: '72',
                    label: '已取消'
                }], // 需求单状态
                wayAddressLong: '',  // 长度
                form: {
                    productForm: '12',  // 类型
                    productStartCityName: this.$store.state.location.cityName,  // 用车城市
                    reserveTime: tomorrowData,  // 用车日期
                    carTime: '09:00',  // 用车时间
                    budgetPer: '',  // 人均价格
                    tripDay: '0.5',  // 游玩天数
                    departure: {
                        city: "上海市",
                        title: ""
                    },  // 出发地址
                    destination: {
                        city: "上海市",
                        title: ""
                    },  // 送达地址
                    vehicleType: 14,  //车辆需求
                    playPersonNumber: '1',  // 出行人数
                    tripUserName: '',  // 出行人
                    tripUserMobile: '',  // 出行手机号
                    orderRemake: '',  // 备注
                    customizationFileUrl: '',  //上传
                    itineraryInfo: [],
                    productOperatorId: '',  // 运营商
                    resourceSupplierId: '',  // 供应商
                },
                resourceCarSeat: '08', // old车辆需求
                wayAddressLength: 1,
                operatorInfo: null,  // 选中运营商
                supplierInfo: null,  // 当前选中供应商
                operatorOptions: null,  // 运营商
                supplierOptions: null,
                stationList: [],
                stationList1: [],
                serverCityList: [],
                citySiteOnId: '',
                citySiteOffId: '',
                formRules: {
                    departure: [{
                        validator: async (rule, value, callback) => {
                            if (!value)
                                callback("出发地址不能为空");
                            else {
                                if (!this.form.departure.value)
                                    callback("请选择正确的出发地址");
                                else callback()
                            }
                        }, required: true, trigger: 'change'
                    }],
                    destination: [{
                        validator: async (rule, value, callback) => {
                            if (!value)
                                callback("送达地址不能为空");
                            else {
                                if (!this.form.destination.value)
                                    callback("请选择正确的送达地址");
                                else callback()
                            }
                        }, required: true, trigger: 'change'
                    }],
                    playPersonNumber: [{
                        validator: async (rule, value, callback) => {
                            if (!value)
                                callback("请选择出行人数");
                            else
                                callback()
                        }, required: true, trigger: 'blur'
                    }],
                    tripUserName: [{
                        validator: async (rule, value, callback) => {
                            if (!value)
                                callback("请填写出行人姓名");
                            else
                                callback()
                        }, required: true, trigger: 'blur'
                    }],

                    tripUserMobile: [{
                        validator: async (rule, value, callback) => {
                            if (!value)
                                callback("出行人手机号不能为空");
                            else {
                                if (!isCellphone(this.form.tripUserMobile))
                                    callback("出行人手机号格式错误");
                                else callback()
                            }
                        }, required: true, trigger: 'blur'
                    }],

                    // customizationFileUrl: [
                    // {required: true, validator: '请上传行程文件', trigger: 'blur'}
                    // ],

                    reserveTime: [{required: true, message: '用车时间不能为空', trigger: 'blur'}],


                    productOperatorId: [{required: true, message: '请选择运营商', trigger: 'blur'}],
                    orderAmount: [{required: true, message: '请填写订单金额', trigger: 'blur'}]
                }
            }
        },
        props: {
            platformType: {
                type: String,
                default: ""
            }  // 1tds  2bms

        },
        components: {
            datePickerWithTitle,
            timePickerWithTitle,
            // searchAddressWithTitle
            searchAddress,
            selectCity,
            fileUpload,
            SeatModel
        },
        //计算属性
        computed: {
            tripDaysOp: function () {
                let tripDayItem = [0.5];
                for (let i = 1; i <= 20; i++) {
                    tripDayItem.push(i)
                }
                return tripDayItem
            }
        },
        created: function () {

            if (this.platformType == 1) {
                this.formSize = '';
                this.heightBox = '40'
            }
            if (this.platformType == 2) {
                this.formSize = 'small'
                this.heightBox = '32'
            }
        },
        //主件被加载完成
        mounted: function () {
            this.getServerCity();
            if (this.platformType == 2) {

                this.getOperator();
                // this.submitDemand();
                this.getOrderDetail();
            }
            this.dayChange(this.form.tripDay)
        },

        //定义事件方法
        methods: {
            async getServerCity() {
                const ret = await getCityData(this, '2');
                if (ret)
                    this.serverCityList = ret;
            },
            async getStation(id, v) {
                let form = {
                    shortCode: id,
                    stationType: 2
                }
                let url = '/galaxyStationTds/doStationList';
                if (this.platformType == 2) url = '/galaxyStationApi/doStationList'

                let res = await this.http(url, form, 'POST');
                console.log(res);
                if (res.success) {
                    switch (v) {
                        case 1:
                            this.stationList = res.data;
                            break;
                        case 2:
                            this.stationList1 = res.data;
                            break
                    }
                }
            },
            handleCitySite(v, i) {
                switch (i) {
                    case 1:
                        this.$refs["form"].clearValidate("departure");
                        // this.searchForm.stationId = v.id;
                        this.citySiteOnId = v.id;
                        this.form.departure = v;
                        if (v) {
                            this.form.departure = {
                                address: v.stationName,
                                city: v.cityName,
                                location: v.longitude + ',' + v.latitude,
                                title: v.stationName,
                                value: v.stationName,
                            }
                        }
                        break;
                    case 2:
                        this.$refs["form"].clearValidate("destination");
                        // this.searchForm.stationId = v.id;
                        this.citySiteOffId = v.id;
                        this.form.destination = v;
                        if (v) {
                            this.form.destination = {
                                address: v.stationName,
                                city: v.cityName,
                                location: v.longitude + ',' + v.latitude,
                                title: v.stationName,
                                value: v.stationName,
                            }
                        }
                        break;
                }

            },  // 上车地点

            async getOperator() {
                let res = await this.http('/galaxyOperator/operatorInfoList', '', 'POST');
                if (res && res.success) {
                    this.operatorOptions = res.data;
                    this.operatorOptions.forEach(item => {
                        item.value = item.id;
                        item.label = item.operatorName;
                    })
                }
            },  // 运营商列表
            handOperatorChange(item) {
                this.operatorInfo = this.operatorOptions.find(item1 => (item1.id == item));
                this.getSupplierList(this.operatorInfo.id);
                this.form.resourceSupplierId = '';
            },  // 运营商选中
            async getSupplierList(id) {
                let res = await this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', {operatorId: id}, 'POST', false);
                if (res.success && res.data) {
                    this.supplierOptions = res.data;
                    this.supplierOptions.forEach(item => {
                        item.value = item.id;
                        item.label = item.supplierName
                    })
                }
            },  // 供应商列表
            handSupplierChange(id) {
                this.form.resourceSupplierId = id;
                this.supplierInfo = this.supplierOptions.find(item => (item.id === id));
                //el-select手动赋值后 会导致选择不上的问题 这里强制更新下
                this.$forceUpdate()
            },  // 供应商选中


            dayChange(days) {
                this.form.tripDay = days;
                if (this.form.reserveTime && days) {
                    this.form.itineraryInfo = [];
                    let infoBox = [];
                    let dayCount = 0;
                    dayCount = days;
                    if (dayCount == 0.5) dayCount = 1;

                    for (let i = 1; i <= dayCount; i++) {
                        let day = new Date(this.form.reserveTime).getTime() + (i - 1) * 8.64e7;
                        // let dateTime = this.timeObj(day).month + 1 + '月' + this.timeObj(day).date + '日';
                        let info = {
                            travelDay: i,
                            // stopPoint: [],
                            handTripTimeHour: '09',
                            handTripTimeMinute: '00',
                            wayAddressList: [
                                // {tripWayPlace:''}  // 途径地址
                            ],
                            tripStartPlace: {},  // 行程 出发地址
                            tripEndPlace: {},  // 行程 送达地址
                            dateTime: this.filterDate(day, "MM月dd日"),
                            handTripTime: '09:00',
                            // date: this.filterDate(new Date(day),"yyyy/MM/dd hh:mm:ss"),
                            date: this.filterDate(day, "yyyy/MM/dd"),
                        };
                        infoBox.push(info);
                        this.form.itineraryInfo = infoBox;
                    }
                } else {
                    this.form.itineraryInfo = [];
                }

            },

            addWayAddress(index) {
                this.form.itineraryInfo[index].wayAddressList.push({id: +new Date(), tripWayPlace: {}});
            },
            deleteWayAddress(index, idxAddress) {
                this.form.itineraryInfo[index].wayAddressList.splice(idxAddress, 1);
            },
            handTripStartPlace(val, indexIdent) {
                if (indexIdent) this.form.itineraryInfo[indexIdent.index].tripStartPlace = val;
            },  // 行程信息 出发地址
            handWayAddressPlace(val, indexIdent) {
                if (indexIdent) this.form.itineraryInfo[indexIdent.index].wayAddressList[indexIdent.idxAddress].tripWayPlace = val;
            },  // 行程信息 途径地址

            handTripEndPlace(val, indexIdent) {
                if (indexIdent) this.form.itineraryInfo[indexIdent.index].tripEndPlace = val;
            },  // 行程信息 送达地址
            handTripTime(time, indexIdent) {  // 接 用车小时
                this.form.itineraryInfo[indexIdent.index].handTripTime = time;

                this.form.itineraryInfo[indexIdent.index].handTripTimeHour = time.substring(0, 2);
                this.form.itineraryInfo[indexIdent.index].handTripTimeMinute = time.substring(3);
            },
            fileUpload(val) {
                this.form.customizationFileUrl = val;
            },
            uploadFileLook(link) {
                window.open(link);
            },
            handStartPlace(val) {
                this.form.departure = val;
                if (val.value)
                    this.$refs["form"].clearValidate("departure");
                let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
                if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 1)
            },  // 出发地点
            handEndPlace(val) {
                this.form.destination = val;
                if (val.value)
                    this.$refs["form"].clearValidate("destination");
                let cityInfo = this.serverCityList.find(c => (val.city == c.city)) || {};
                if (cityInfo.shortCode) this.getStation(cityInfo.shortCode, 2)
            },  // 送达地点
            handVehicleDate(data) {  // 接 用车日期
                this.form.reserveTime = data;
            },
            handVehicleTime(time) {  // 接 用车小时
                console.log(time);
                this.form.carTime = time;
            },
            handleTypeBtn(valve) {
                this.form.productForm = valve;
                this.form.playPersonNumber = 1
            },
            handleChange(value) {
                console.log(value);
            },
            handSaddleTypeChange(value) {
                this.form.playPersonNumber = 1;
                this.maxPeopleNum = getSeatModel(parseInt(value)).seat;
            },  // 车座车型 change
            settleAmountChange(value) {
                this.form.settleAmount = keepTwoDecimal(value)

            },   // 结算金额
            orderAmountChange(value) {
                this.form.orderAmount = keepTwoDecimal(value)
                this.$set(this.form,'settleAmount',keepTwoDecimal(Number(value) * .8))
            },  // 订单金额小数点格式化
            budgetPerChange(value) {
                this.form.budgetPer = keepTwoDecimal(value)

            }, // 人均价格// 金额小数点格式化

            hideCitySel(b) {
                this.showHideCity = b
            },
            selectDestCityName(city) {
                this.form.productStartCityName = city;
            },
            submitDemand() {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {


                        let form = {
                            productForm: this.form.productForm,  //类型 12 接送服务   2 旅游包车    13 旅游线路
                        }

                        let tripDate = ''
                        switch (this.form.productForm) {
                            case "12":
                                tripDate = this.filterDate(new Date(`${this.form.reserveTime}` + ' ' + `${this.form.carTime}`), "yyyy/MM/dd hh:mm:ss");
                                form.reserveTime = +new Date(tripDate);  // 日期时间
                                form.departure = JSON.stringify(this.form.departure);  // 出发地址
                                form.destination = JSON.stringify(this.form.destination);  // 送达地址
                                form.vehicleType = this.form.vehicleType;  // 车型需求
                                form.resourcePriceWay = 0;
                                break;
                            case "2":
                                form.productStartCityName = this.form.productStartCityName;  // 用车城市
                                form.tripDay = this.form.tripDay;  // 用车天数 ,
                                form.vehicleType = this.form.vehicleType;  // 车型需求
                                form.resourcePriceWay = 0;
                                break;
                            case "13":
                                form.productStartCityName = this.form.productStartCityName;  // 用车城市
                                // form.reserveTime = +new Date(this.form.reserveTime);  // 日期
                                form.tripDay = this.form.tripDay;  // 用车天数 ,
                                break;

                        }  //类型 12 接送服务   2 旅游包车    13 旅游线路
                        form.playPersonNumber = this.form.playPersonNumber; //  出行人数 ,
                        form.tripUserMobile = this.form.tripUserMobile; // 出行人手机号 ,
                        form.tripUserName = this.form.tripUserName; //  出行人姓名
                        form.budgetPer = this.form.budgetPer; // 人均预算 ,
                        form.orderRemake = this.form.orderRemake; // 订单备注 ,
                        form.customizationFileUrl = this.form.customizationFileUrl;  // 需求文档文件
                        if (this.form.productForm != 12) {
                            form.departure = JSON.stringify(this.form.itineraryInfo[0].tripStartPlace);  // 出发地址
                            let lastDestination = this.form.itineraryInfo[this.form.itineraryInfo.length - 1];
                            form.destination = JSON.stringify(lastDestination.tripEndPlace);  // 送达地址
                            let tripDate = this.filterDate(new Date(`${this.form.itineraryInfo[0].date}` + ' ' + `${this.form.itineraryInfo[0].handTripTime}`), "yyyy/MM/dd hh:mm:ss");
                            form.reserveTime = +new Date(tripDate);  // 日期时间
                        }

                        form.itineraryInfo = JSON.stringify(this.form.itineraryInfo); // 行程信息 ,


                        let res = await this.http('/galaxyOrder/createCustomizationOrder', form, 'POST');
                        if (res.success) {
                            this.$message({
                                message: '需求提交成功',
                                type: 'success'
                            });
                            this.$router.push({
                                name: 'orderList',
                            });


                        }
                    }
                })
            },
            downUrl(url) {

                download(url)

            },
            async getOrderDetail() {
                let res = await this.http(`/galaxyOrderCustomize/customizeOrderDetail/${this.$route.params.orderId}`)
                if (res.success) {
                    this.form = res.data;
                    if (this.form.productForm == 12) {
                        this.form.reserveTime = this.filterDate(this.form.reserveTime, "yyyy-MM-dd hh:mm");
                        this.form.carTime = this.filterDate(this.form.reserveTime, "hh:mm");
                        this.deTimeHour = this.filterDate(this.form.reserveTime, "hh");
                        this.deTimeMinute = this.filterDate(this.form.reserveTime, "mm");
                    } else {
                        this.form.reserveTime = this.filterDate(this.form.reserveTime, "yyyy-MM-dd");
                    }

                    this.form.itineraryInfo = JSON.parse(res.data.itineraryInfo); // 行程信息 ,
                    if (res.data.departure) {
                        this.form.departure = JSON.parse(res.data.departure);  // 出发地址
                        let cityInfo = this.serverCityList.find(c => (this.form.departure.city == c.city)) || {};
                        this.getStation(cityInfo.shortCode, 1)
                    }
                    if (res.data.destination) {
                        this.form.destination = JSON.parse(res.data.destination);  // 送达地址
                        let cityInfo = this.serverCityList.find(c => (this.form.destination.city == c.city)) || {};
                        this.getStation(cityInfo.shortCode, 2)
                    }
                    this.getSupplierList(this.form.productOperatorId)
                    this.form.vehicleType = parseInt(res.data.vehicleType)

                }
            },  // 详情

            updateCustomizeOrder() {

                let form = {};
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        form = JSON.parse(JSON.stringify(this.form));

                        form.itineraryInfo = JSON.stringify(form.itineraryInfo); // 行程信息 ,
                        form.departure = JSON.stringify(form.departure);  // 出发地址
                        form.destination = JSON.stringify(form.destination);  // 送达地址
                        // POST
                        if (form.productForm === 12) {
                            let reserveTime = this.filterDate(this.form.reserveTime, "yyyy-MM-dd");
                            let tripDate = this.filterDate(new Date(`${reserveTime}` + ' ' + `${form.carTime}`), "yyyy/MM/dd hh:mm:ss");
                            form.reserveTime = +new Date(tripDate);  // 日期时间
                        } else {
                            form.reserveTime = +new Date(form.reserveTime);
                        }

                        // 4743945
                        let res = await this.http('/galaxyOrderCustomize/updateCustomizeOrder', form, 'POST')
                        if (res.success) {
                            this.$message({
                                message: '报价成功',
                                type: 'success'
                            });
                            this.$store.commit('delete_tabs', `/demandSheetEdit/${this.$route.params.orderId}`);
                            this.$router.push({
                                name: 'demandSheet',
                            });
                        }
                    }
                })

            },
            formatCarModel(type) {
                let info = {};
                if (type) {
                    info = getSeatModel(parseInt(type));
                    // console.log(info, type);
                }
                return info;
            },
        },
        //监听模版变量
        watch: {
            "form.productForm"(n) {

                if (n == 3) this.maxPeopleNum = 100;

            }
        }

    }
</script>
